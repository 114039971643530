/**
 * Site header
 */
.site-header {
	background-color: $purple;
	min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
	
	.wrapper {
		height: $spacing-unit*12;
		padding-top: 0;
		padding-bottom: 0;
	}


	.site-title {
	  @include relative-font-size(1.625);
	  font-weight: 300;
	  letter-spacing: -1px;
	  margin-bottom: 0;
	  float: left;

	  &,
	  &:visited {
	    color: $grey-color-dark;
	  }
	  svg {
		display: block;
	    max-height: 100px;
	    padding: 10px 0;
	    width: auto;
	
		@include media-query($on-palm) {
			max-height: 80px;
		}
	  }
	  h1 {
		display: block;
	    height: 0;
		margin: 0;
	    width: 0;
	    opacity: 0;
	  }
	}

	.site-nav {
		align-items: center;
	    color: $white;
		float: right;
		display: flex;
		height: 100%;
	
	  .nav-trigger {
	      display: none;
	  }

	  .menu-icon {
	    display: none;
	  }

	  .page-link {
	    color: $white;
	    line-height: $base-line-height;
		margin-left: 10px;
		margin-right: 10px;

	    // Gaps between nav items, but not on the last one
	    //&:not(:last-child) {
	    //  margin-right: 20px;
	    //}
		&:hover,
		&:focus {
			color: $green;
			text-decoration: none;
		}
	  }

	  @include media-query($on-palm) {
		display: block;
		height: auto;
	    position: absolute;
	    top: 33px;
	    right: $spacing-unit;
	    background-color: $purple;
	    text-align: right;
	    z-index: 2;

	    label[for="nav-trigger"] {
	      display: block;
	      float: right;
	      width: 36px;
	      height: 36px;
	      z-index: 2;
	      cursor: pointer;
	    }
		
		.menu-divider {
			display: none;
		}

	    .menu-icon {
	      display: block;
	      float: right;
	      width: 36px;
	      height: 26px;
	      line-height: 0;
	      padding-top: 10px;
	      text-align: center;

	      > svg {
	        fill: $white;
	      }
	    }

	    input ~ .trigger {
	      clear: both;
	      display: none;
	    }

	    input:checked ~ .trigger {
	      display: block;
	      padding-bottom: 5px;
		  padding-top: 20px;
	    }

	    .page-link {
	      display: block;
	      padding: 5px 10px;
		  margin-right: 0;

	      //&:not(:last-child) {
	      //  margin-right: 0;
	      //}
	      margin-left: 20px;
	    }
	  }
	}
}

/* Submenu */

.sub-menu-trigger {
	cursor: default;
	padding-bottom: $spacing-unit*20;
	position: relative;
}
.sub-menu-trigger ul {
    background: $purple;
    margin-left: -20px;
    min-width: 200px;
    padding: 10px 20px;
	position: absolute;
    z-index: 2;
}

.sub-menu-trigger ul li {
    line-height: $base-line-height;
	list-style: none;
	margin-bottom: 5px;
	
	a {
		color: $white;
	
		&:hover,
		&:focus {
			color: $green;
			text-decoration: none;
		}
	}
	&.last {
        margin-bottom: 0;
    }
}

.sub-menu-trigger .sub-menu {
	display: none;
	
	@include media-query ($on-palm){
		display: block;
		padding: 0;
		position: initial;
	}
}
.sub-menu-trigger:hover .sub-menu,
.sub-menu-trigger:focus .sub-menu {
	display: block;
}

/* Homepage Header */



/* Homepage Header */

.header-home {
	background: none;
	
	.wrapper {
		padding-top: 0;
		padding-bottom: 0;
	}

	.site-title {
		@include relative-font-size(1.625);
		font-weight: 300;
		letter-spacing: -1px;
		margin-bottom: 0;
		float: left;

		&,
		&:visited {
		  color: $grey-color-dark;
		}
		svg {
			display: block;
		  max-height: 100px;
		  padding: 10px 0;
		  width: auto;
	
			@include media-query($on-palm) {
				max-height: 80px;
			}
		}
		h1 {
			display: block;
		  height: 0;
			margin: 0;
		  width: 0;
		  opacity: 0;
		}
	}

	.site-nav {
		color: $white;
		float: none;
		margin: 0 auto;
		width: 320px;
		width: fit-content;
		line-height: $base-line-height * $base-font-size * 2.25;

	  .nav-trigger {
	      display: none;
	  }

	  .menu-icon {
	    display: none;
	  }

	  .page-link {
	    color: $white;
	    line-height: $base-line-height;
		margin-left: 10px;
		margin-right: 10px;

	    // Gaps between nav items, but not on the last one
	    //&:not(:last-child) {
	    //  margin-right: 20px;
	    //}
		&:hover,
		&:focus {
			color: $green;
			text-decoration: none;
		}
	  }
	}
	
	.sub-menu-trigger .sub-menu {
	
		@include media-query ($on-palm){
			display: none;
			padding: 10px 20px;
			position: absolute;
		}
	}
	.sub-menu-trigger:hover .sub-menu,
	.sub-menu-trigger:focus .sub-menu {
		display: block;
	}
}