.leftalign {
	float: left;
	padding-right: $spacing-unit * 4;
	width: -webkit-calc(50% - (#{$spacing-unit} * 6));
	width: calc(50% - (#{$spacing-unit} * 6));
	
	@include media-query($on-700) {
		margin-bottom: $spacing-unit*4;
	}
}
.rightalign {
	border-left: 1px solid $purple;
	float: left;
	padding-left: $spacing-unit * 4;
	width: -webkit-calc(50% - (#{$spacing-unit} * 6));
	width: calc(50% - (#{$spacing-unit} * 6));
}
@include media-query($on-700) {
	.leftalign, .rightalign {
		border: none;
		float: none;
		padding: 0;
		width: 100%;
	}
}

.callus {
	color: $purple;
	
	h4 {
		font-size: 2.8rem;
		font-weight: normal;
		line-height: 1.1;
		
		@include media-query($on-1200) {
			font-size: 2.5rem;
		}
		@include media-query($on-1000) {
			font-size: 2rem;
		}
		@include media-query($on-800) {
			font-size: 1.6rem;
		}
		@include media-query($on-600) {
			font-size: 2.2rem;
		}
		@include media-query($on-500) {
			font-size: 1.6rem;
		}
		@include media-query($on-400) {
			font-size: 1.2rem;
		}
	}
	.wrapper {
		margin: $spacing-unit*2 0;
		max-width: 100%;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 0;
	}
	.callus-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		
		.content-left {
			width: 40%;
			
			@include media-query($on-300) {
				width: 100%;
			}
		}
		.content-right {
			padding-left: $spacing-unit*6;
			max-width: 600px;
			width: 60%;
			
			@include media-query($on-800) {
				padding-left: $spacing-unit*2;
			}
			@include media-query($on-300) {
				padding-left: $spacing-unit;
				padding-right: $spacing-unit;
				width: calc(100% - (#{$spacing-unit}*2));
			}
		}
		@include media-query($on-300) {
			display: block;
		}
		
		svg {
			height: auto;
			width: 100%;
			
			path {
				fill: $purple;
			}
		}
		//@include media-query($on-700) {
		//	margin-left: 30%;
		//	width: 70%;
		//}
	}
	.tel1 {
		display: block;
		
		@include media-query($on-600) {
			display: none;
		}
	}
	.tel2 {
		display: none;
		
		@include media-query($on-600) {
			display: block;
		}
	}
}
.intro {
	background-image: url(/assets/images/1407562A.jpg);
	background-position: center right;
	background-repeat: no-repeat;
	background-size: cover;
	color: $white;
	
	svg {
		height: auto;
		margin-bottom: $spacing-unit*2;
		max-width: 460px;
		width: 100%;
	}
	.banner {
		margin: $spacing-unit*6 0;
		max-width: 50%;
		
		h1,p {
			font-size: $spacing-unit*3.4;
			font-weight: $light-font-weight;
			margin-bottom: 0;
			
			@include media-query($on-400) {
				font-size: $spacing-unit*2.8;
			}
		}
		h1 {
			margin-bottom: $spacing-unit*2;
		}
		@include media-query($on-600) {
			margin: $spacing-unit*2 0;
			max-width: 100%;
		}
	}
	@include media-query($on-1200) {
		background-image: url(/assets/images/1407562B.jpg);
	}
}

.info {
	color: $purple;
	
	ul {
		margin-left: 0;
	}
	li {
		font-size: $spacing-unit*3.6;
		line-height: 1.1;
		list-style: none;
		margin-left: 0;
		margin-bottom: $spacing-unit*1.5;
		
		@include media-query($on-400) {
			font-size: $spacing-unit*2.8;
		}
	}
	.wrapper {
		padding-top: $spacing-unit * 4;
		padding-bottom: $spacing-unit * 4;
	}
	.info-top {
		margin-bottom: $spacing-unit * 4;
		@extend %clearfix;
	}
	.info-1 {
		float: left;
		font-weight: 900;
		padding-right: $spacing-unit * 4;
		text-align: right;
		//width: -webkit-calc(50% - (#{$spacing-unit} * 10));
		//width: calc(50% - (#{$spacing-unit} * 10));
		width: 330px;
		@include media-query($on-800) {
			width: 240px;
		}
		@include media-query($on-600) {
			margin-bottom: 40px;
		}
		
		svg {
			float: right;
			max-width: $spacing-unit*20;
			margin-bottom: $spacing-unit*4;
			
		}
	}
	.info-2 {
		border-left: 1px solid $purple;
		float: left;
		padding-left: $spacing-unit * 4;
		//width: -webkit-calc(50% - (#{$spacing-unit} * 6));
		//width: calc(50% - (#{$spacing-unit} * 6));
		width: calc(100% - 420px);
		@include media-query($on-800) {
			width: calc(100% - 330px);
		}
		
		p {
			font-size: 1.3rem;
			
			@include media-query($on-400) {
			font-size: 1.2rem;
			}
		}
	}
	.info-divider {
		
	}
	@include media-query($on-600) {
		.info-1, .info-2 {
			border: none;
			float: none;
			padding: 0;
			text-align: left;
			width: 100%;
			
			svg {
				float: none;
				width: 80%;
			}
		}
	}
}

.more {
	background: url(/assets/images/1440816.jpg);
	background-color: $white;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: contain;
	
	h3 {
		color: $purple;
		font-size: 2.8rem;
		font-weight: 700;
		margin-top: $spacing-unit*4;
		text-align: center;	
	}
	h4 {
		font-size: 1.6rem;
		font-weight: 900;
		text-align: center;
	}
	img {
		display: block;
		margin: 0 auto;
		width: 200px;
		height: auto;
	}
	.icons {
		margin: $spacing-unit*6 auto;
	}
	.icon {
		svg {
			display: block;
			margin: 0 auto $spacing-unit*2;
			width: 200px;
			height: auto;
		}
		a {
			color: $purple;
		}
		a svg circle {
			fill: $purple;
		}
		a:hover,
		a:focus {
			color: $green;
			text-decoration: none;
		}
		a:hover svg circle,
		a:focus svg circle {
			fill: $green;
		}
    }
}

.post-header-image {
	align-content: flex-start;
	background-position: center right;
	background-repeat: no-repeat;
	background-size: cover;
	color: $white;
	min-height: $spacing-unit * 50;
	position: relative;

	&.about {
		background-image: url('/assets/images/2271.jpg');
	}
	&.buy-to-let {
		background-image: url('/assets/images/1516363330.jpg');
	}
	&.contact {
		background-image: url('/assets/images/2691454.jpg');
	}
	&.first-time-buyers {
		background-image: url('/assets/images/792034.jpg');
	}
	&.home-movers {
		background-image: url('/assets/images/658680974.jpg');
	}
	&.insurance-protection {
		background-image: url('/assets/images/3443977.jpg');
	}
	&.remortgages {
		background-image: url('/assets/images/1946371.jpg');
	}
	&.secured-loans {
		background-image: url('/assets/images/2889042.jpg');
	}
	.shade {
		background-color: rgba(0,0,0,0.4);
		position: absolute;
		height: 100%;
		width: 100%;
	}
	.wrapper {
		align-items: center;
		display: flex;
		right: 0;
		left: 0;
		max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 6));
		max-width:         calc(#{$content-width} - (#{$spacing-unit} * 6));
		padding-top: 0;
		padding-bottom: 0;
		position: absolute;
		height: 100%;
		
		
		h1 {
			z-index: 1;
		}
	}
	@include media-query ($on-600) {
		min-height: $spacing-unit * 30;
	}
	@include media-query ($on-400) {
		min-height: $spacing-unit * 20;
	}
}

.post-header.privacy .post-title {
	max-width: 100%;
}
.privacy-wrapper {
	padding-top: 0;
}