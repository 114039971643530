@charset "utf-8";

// Define defaults for each variable.

$base-font-family: 'Lato', sans-serif !default;
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$light-font-weight:300 !default;
$small-font-size:  14px !default;
$base-line-height: 1.4 !default;

$spacing-unit:     10px !default;

$black:             #000;
$white:             #fff;
$purple:            #2D1664;
$green:             #52BA2A;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      $purple;
$button-color:     $purple;
$button-hover:     lighten($button-color, 20%);

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area

$on-300:            300px;
$on-400:            400px;
$on-500:            500px;
$on-600:            600px;
$on-700:            700px;
$on-800:            800px;
$on-900:            900px;
$on-1000:           1000px;
$on-1100:           1100px;
$on-1200:           1200px;
$on-1300:           1300px;

$content-width:    $on-1200 !default;

$on-palm:          600px !default;
$on-laptop:        900px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/header",
  "minima/footer",
  "minima/layout",
  "minima/pages",
  "minima/syntax-highlighting"
;
