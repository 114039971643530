/**
 * Page content
 */
.page-content {
  flex: 1;
  -ms-flex: 1 0 auto;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;
  max-width: 50%;
  text-transform: uppercase;

  @include media-query($on-800) {
    max-width: initial;
  }
  @include media-query($on-400) {
    @include relative-font-size(2);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
