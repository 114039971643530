/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  font: $light-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: auto;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit*1.5;
}


/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: $light-font-weight;
}
h1 {
	font-size: $spacing-unit*3.6;
	
	@include media-query($on-400) {
		font-size: $spacing-unit*2.8;
	}
}
h2 {
	font-size: $spacing-unit*2.8;
	
	@include media-query($on-400) {
		font-size: $spacing-unit*2.4;
	}
}
h3 {
	font-size: $spacing-unit*2.4;
	
	@include media-query($on-400) {
		font-size: $spacing-unit*2;
	}
}
h4 {
	font-size: $spacing-unit*2;
	
	@include media-query($on-400) {
		font-size: $spacing-unit*1.8;
	}
}


/**
 * Paragraphs
 */
p {
  font-weight: $base-font-weight;
}



/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}
svg {
	width: 100%;
	height: auto;
}


/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/* Custom Lists */
ul.list {
	margin: 0;
	
	@extend %clearfix;
}
ul.list li {
	list-style: none;
	float: left;
	margin-bottom: $spacing-unit*3;
}
li h3 {
	margin: ($spacing-unit*0.8) 0 0;
}

li.full {
	width: 100%;
}
li.half {
	width: 48.5%;
	margin-right: 3%;
	
	&:nth-child(2n+2) { margin-right: 0; }
	
	@include media-query($on-400) {
		width: 100%;
		margin-right: 0;
	}
}
li.third {
	width: 31.3%;
	margin-right: 3%;
	
	&:nth-child(3n+3) { margin-right: 0; }
	
	@include media-query($on-800) {
    width: 48.5%;
    &:nth-child(3n+3) { margin-right: 3%; }
    &:nth-child(2n+2) { margin-right: 0; }
	}
	@include media-query($on-500) {
		width: 100%;
		margin-right: 0;
	}
}
li.two-thirds {
	width: 65.7%;
	margin-right: 3%;
	
	@include media-query($on-400) {
		width: 100%;
		margin-right: 0;
	}
}
li.quarter {
	width: 22.7%;
	margin-right: 3%;
	
	&:nth-child(4n+4) { margin-right: 0; }
	
	@include media-query($on-800) {
		width: 48.5%;
		&:nth-child(3n+3) { margin-right: 3%; }
		&:nth-child(2n+2) { margin-right: 0; }
	}
	@include media-query($on-400) {
		width: 100%;
		margin-right: 0;
	}
}



/**
 * Links
 */
a {
  color: $brand-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: lighten($brand-color, 33%);
		text-decoration: underline;
  }
  &:visited {
    color: darken($brand-color, 33%);
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}

/**
 * Buttons
 */
button,
a.button,
button.button,
submit,
submitbutton,
input.button,
input[type=submit] {
  background: $button-color;
  color: $white;
  font-family: $base-font-family;
  padding: ($spacing-unit * 1) ($spacing-unit * 2);
	text-transform: uppercase;
	width: initial;
}
button:hover,
a.button:hover,
button.button:hover,
submit:hover,
submitbutton:hover,
input.button:hover,
input[type=submit]:hover,
button:focus,
a.button:focus,
button.button:focus,
submit:focus,
submitbutton:focus,
input.button:focus,
input[type=submit]:focus {
	color: $button-hover;
}


/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: $spacing-unit / 2;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 6));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 6));
  margin-right: auto;
  margin-left: auto;
  padding: $spacing-unit * 6;
  @extend %clearfix;

  @include media-query($on-laptop) {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 4));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 4));
    padding-right: $spacing-unit * 2;
    padding-left: $spacing-unit * 2;
  }
  @include media-query($on-palm) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
  }
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Icons
 */

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: #{$grey-color};
    padding-right: 5px;
    vertical-align: text-top;
}

.social-media-list {
  li + li {
    padding-top: 5px;
  }
}



/**
 * Tables
 */
table {
  margin-bottom: $spacing-unit;
  width: 100%;
  text-align: $table-text-align;
  color: lighten($text-color, 18%);
  border-collapse: collapse;
  border: 1px solid $grey-color-light;
  tr {
    &:nth-child(even) {
      background-color: lighten($grey-color-light, 6%);
    }
  }
  th, td {
    padding: ($spacing-unit / 3) ($spacing-unit / 2);
  }
  th {
    background-color: lighten($grey-color-light, 3%);
    border: 1px solid darken($grey-color-light, 4%);
    border-bottom-color: darken($grey-color-light, 12%);
  }
  td {
    border: 1px solid $grey-color-light;
  }
}

/**
 * Forms
 */

form {
  color: $text-color;
	font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
	margin: $spacing-unit*4 0;
	
	input, textarea {
		border: none;
		border-bottom: 1px solid lighten($text-color, 50%);
	  color: $text-color;
		font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
		display: block;
		margin-bottom: $spacing-unit*2;
		width: 100%;
			
		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: lighten($text-color, 50%);
		}
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: lighten($text-color, 50%);
		}
		&::-ms-input-placeholder { /* Microsoft Edge */
			color: lighten($text-color, 50%);
		}
	}
	input[type="submit"]{
		background: $button-color;
		color: $white;
		padding: ($spacing-unit * 1) ($spacing-unit * 2);
		width: auto;
		
		&:hover,
		&:focus {
			background: $button-hover;
			color: $white;
		}
	}
	input[type="checkbox"]{
    display: initial;
		width: auto;
	}
}