/**
 * Site footer
 */
.site-footer {
  background: $purple;
  color: $white;
  border-top: 1px solid $grey-color-light;
  font-size: $small-font-size;
  padding: $spacing-unit 0;
  
  svg {
    height: auto;
    width: 100%;
    max-width: 160px;
  }
  a {
    color: $green;
    
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

.footer-heading {
  height: 0;
  width: 0;
  opacity: 0;
  @include relative-font-size(1.125);
  margin-bottom: 0;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  color: $white;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit * 4;
}

.footer-col-1 {
  margin-right: $spacing-unit * 2;
  width: $spacing-unit * 20;
}

.footer-col-2 {
  width: -webkit-calc(100% - (#{$spacing-unit} * 22));
  width: calc(100% - (#{$spacing-unit} * 22));
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
  }
  .footer-col-2 {
    width: 100%;
  }
}

.credit {
  text-align: center;
}

